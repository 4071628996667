import { Project } from '@core/domain/models/project.model'
import { Id } from '@core/domain/types/id.type'
import { Api } from './review-manager-api'

export class ProjectsRepository {
  constructor(private _reviewManagerService: Api<unknown>) {}
  async create(params: { name: string }): Promise<Project> {
    const { data } =
      await this._reviewManagerService.api.projectsControllerCreate(params)
    return new Project(data as Partial<Project>)
  }

  async find(): Promise<Project[]> {
    const { data } =
      await this._reviewManagerService.api.projectsControllerFind()
    return data.map((p: unknown) => new Project(p as Partial<Project>))
  }
  async findById(projectId: number): Promise<Project> {
    const { data } =
      await this._reviewManagerService.api.projectsControllerFindById(projectId)
    return new Project(data as Partial<Project>)
  }

  async createReview(projectId: Id, name: string): Promise<void> {
    await this._reviewManagerService.api.projectsControllerCreateReview(
      projectId,
      {
        name,
      },
    )
  }

  async delete(projectId: Id): Promise<void> {
    await this._reviewManagerService.api.projectsControllerDelete(projectId)
  }

  async downloadPdfZip(projectId: Id, selectedReviewsIds: Id[]) {
    const { data } = await this._reviewManagerService.instance.get(
      `/api/projects/${projectId}/pdf/${selectedReviewsIds}/studies/zip`,
      {
        responseType: 'blob',
      },
    )

    return data as unknown as Blob
  }

  async updateProjectName(projectId: Id, name: string): Promise<void> {
    await this._reviewManagerService.api.projectsControllerUpdateName(
      projectId,
      { name },
    )
  }

  async updateContactForComplaint(projectId: Id, email: string): Promise<void> {
    await this._reviewManagerService.api.projectsControllerUpdateContactForComplaintEmail(
      projectId,
      { email },
    )
  }

  async updateCslStyle(projectId: Id, cslStyle: string): Promise<void> {
    await this._reviewManagerService.api.projectsControllerUpdateCslStyle(
      projectId,
      { style: cslStyle },
    )
  }

  async addMember(projectId: Id, userId: string): Promise<void> {
    await this._reviewManagerService.api.projectsControllerAddMember(
      projectId,
      userId,
    )
  }

  async removeMember(projectId: Id, userId: string): Promise<void> {
    await this._reviewManagerService.api.projectsControllerRemoveMember(
      projectId,
      userId,
    )
  }

  async setReviewsPlanLocking(projectId: Id, useReviewPlanLocking: boolean) {
    await this._reviewManagerService.api.projectsControllerSetReviewsPlanLocking(
      projectId,
      {
        useReviewPlanLocking,
      },
    )
  }
}
