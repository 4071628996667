<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <rect x="1" y="4" width="30" height="24" rx="4" ry="4" fill="#fff"></rect>
    <path
      d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
      opacity=".15"
    ></path>
    <path
      transform="rotate(-56.31 8.143 10.762)"
      d="M5.877 10.384H10.41V11.139000000000001H5.877z"
    ></path>
    <path
      transform="rotate(-56.31 9.086 11.39)"
      d="M6.819 11.013H11.352V11.768H6.819z"
    ></path>
    <path
      transform="rotate(-56.31 10.028 12.02)"
      d="M7.762 11.641H12.295V12.396H7.762z"
    ></path>
    <path
      transform="rotate(-56.31 24.538 20.216)"
      d="M23.499 19.839H25.576V20.593999999999998H23.499z"
    ></path>
    <path
      transform="rotate(-56.31 23.176 22.26)"
      d="M22.137 21.882H24.215V22.637H22.137z"
    ></path>
    <path
      transform="rotate(-56.31 23.595 19.588)"
      d="M22.556 19.21H24.633000000000003V19.965H22.556z"
    ></path>
    <path
      transform="rotate(-56.31 22.234 21.632)"
      d="M21.195 21.253H23.272V22.008H21.195z"
    ></path>
    <path
      transform="rotate(-56.31 22.653 18.96)"
      d="M21.614 18.582H23.691000000000003V19.337H21.614z"
    ></path>
    <path
      transform="rotate(-56.31 21.29 21.002)"
      d="M20.252 20.625H22.329V21.38H20.252z"
    ></path>
    <path
      d="M12.229,13.486c1.389-2.083,4.203-2.646,6.286-1.257s2.646,4.203,1.257,6.286l-7.543-5.029Z"
      fill="#be3b3e"
    ></path>
    <path
      d="M12.229,13.486c-1.389,2.083-.826,4.897,1.257,6.286s4.897,.826,6.286-1.257c.694-1.041,.413-2.449-.629-3.143s-2.449-.413-3.143,.629l-3.771-2.514Z"
      fill="#1c449c"
    ></path>
    <circle cx="14.114" cy="14.743" r="2.266" fill="#be3b3e"></circle>
    <path
      transform="rotate(-33.69 8.143 21.238)"
      d="M7.765 18.972H8.52V23.505000000000003H7.765z"
    ></path>
    <path
      transform="rotate(-33.69 10.03 19.98)"
      d="M9.651 17.715H10.406V22.248H9.651z"
    ></path>
    <path
      transform="rotate(-33.69 22.915 11.39)"
      d="M22.537 9.124H23.291999999999998V13.657H22.537z"
    ></path>
    <path
      transform="rotate(-33.69 8.405 19.588)"
      d="M8.027 18.549H8.782V20.625999999999998H8.027z"
    ></path>
    <path
      transform="rotate(-33.691 9.767 21.632)"
      d="M9.389 20.592H10.144V22.668999999999997H9.389z"
    ></path>
    <path
      transform="rotate(-33.69 21.29 10.998)"
      d="M20.913 9.959H21.668V12.036H20.913z"
    ></path>
    <path
      transform="rotate(-33.69 22.652 13.04)"
      d="M22.275 12.002H23.029999999999998V14.079H22.275z"
    ></path>
    <path
      transform="rotate(-33.69 23.176 9.741)"
      d="M22.798 8.702H23.552999999999997V10.779H22.798z"
    ></path>
    <path
      transform="rotate(-33.691 24.539 11.783)"
      d="M24.16 10.745H24.915V12.822H24.16z"
    ></path>
    <path
      d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
      fill="#fff"
      opacity=".2"
    ></path>
  </svg>
</template>
