<template>
  <div class="flex-grow h-full">
    <div class="px-4 text-lg space-y-4">
      <div>
        <GenericAutoComplete
          v-model="problem"
          :readonly="review.isPlanReadonly.value"
          placeholder="select a problem"
          label="Problem"
          :items="problems"
          :item-options="{
            displayProperty: 'text',
            filterProperties: ['text', 'value'],
            valueProperty: 'value',
          }"
        />
      </div>

      <div v-if="problem">
        <GenericAutoComplete
          v-model="purpose"
          :readonly="review.isPlanReadonly.value"
          placeholder="select a purpose"
          label="Purpose"
          :items="purposeOptions"
          :item-options="{
            displayProperty: 'text',
            filterProperties: ['text', 'value'],
            valueProperty: 'value',
          }"
        />
      </div>
      <div v-if="purpose" class="space-x-4">
        <TextInput
          v-model="problemName"
          type="text"
          class="flex-1 focus:outline-none transition-colors"
          :placeholder="`Type ${problem} name`"
          :label="`Specify ${problem} name`"
          @focus="isFocused = true"
          @blur="isFocused = false"
          @keydown.enter="applyPreset"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ref, watch } from 'vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { ReviewPreset } from '@core/domain/types/review-preset.type'

const review = injectStrict(ReviewKey)
const snackbar = useSnackbar()

const isFocused = ref()
const purpose = ref()
const purposeOptions = ref<{ text: string; value: string }[]>([])

const problemName = ref()

const problem = ref()
const loading = useLoading()

async function applyPreset() {
  if (!purpose.value || !problem.value || !problemName.value) {
    snackbar.show(SnackbarState.ERROR, 'Missing or incorrect fields')
    return
  }

  try {
    loading.start()
    await review.addMultipleAttributesStructure(review.entity.value.id, {
      preset: ReviewPreset.MEDICAL_BACKGROUND,
      problem: problem.value,
      problemName: problemName.value,
      purpose: purpose.value,
    })
    snackbar.show(
      SnackbarState.SUCCESS,
      'Review presets generated successfully',
    )
  } catch (error) {
    snackbar.show(SnackbarState.ERROR, 'Error while generating review presets')
    console.error('Error adding scope field:', error)
  } finally {
    loading.stop()
  }

  problem.value = ''
  problemName.value = ''
  purpose.value = ''
}

const problems = [
  {
    text: 'Disease',
    value: 'disease',
  },
  {
    text: 'Injury or Disability',
    value: 'injury or disability',
  },
  {
    text: 'Anatomy of physiological or Pathological process or state',
    value: 'anatomy of physiological or pathological process or state',
  },
]

interface ProblemOptions {
  disease: { text: string; value: string }[]
  'injury or disability': { text: string; value: string }[]
  'anatomy of physiological or pathological process or state': {
    text: string
    value: string
  }[]
}

const problemOptions: ProblemOptions = {
  disease: [
    { text: 'Treatment', value: 'treatment' },
    { text: 'Alleviation', value: 'alleviation' },
    { text: 'Diagnosis', value: 'diagnosis' },
    { text: 'Prevention', value: 'prevention' },
    { text: 'Monitoring', value: 'monitoring' },
    { text: 'Prediction', value: 'prediction' },
    { text: 'Prognosis', value: 'prognosis' },
  ],
  'injury or disability': [
    { text: 'Treatment', value: 'treatment' },
    { text: 'Alleviation', value: 'alleviation' },
    { text: 'Diagnosis', value: 'diagnosis' },
    { text: 'Monitoring', value: 'monitoring' },
    { text: 'Compensation', value: 'compensation' },
  ],

  'anatomy of physiological or pathological process or state': [
    { text: 'Investigation', value: 'investigation' },
    { text: 'Replacement', value: 'replacement' },
    { text: 'Modification', value: 'modification' },
  ],
}

watch(
  () => problem.value,
  (problem: string) => {
    purpose.value = ''
    problemName.value = ''
    const selectedProblem = problem as keyof ProblemOptions
    purposeOptions.value = selectedProblem
      ? problemOptions[selectedProblem] || []
      : []
  },
)
</script>
