<template>
  <div class="px-4 py-4 space-y-6 container">
    <h3 class="text-2xl text-primary font-medium flex items-center gap-2">
      Appraisal
      <InfoTooltip
        text="Appraisal criteria help assess the quality of studies in your review."
        url="/docs/reviews.html#setting-up-appraisal-criteria"
      />
    </h3>
    <div class="space-y-3">
      <div class="flex gap-4">
        <h3 class="text-lg text-primary font-bold flex items-center gap-2">
          Peer review status
        </h3>
        <Switch
          :disabled="review.isPlanReadonly.value"
          :model-value="
            review.entity.value?.plan?.appraisalPlan
              .isPeerReviewStatusApplicable
          "
          @update:model-value="toggleStudiesAppraisalPeerReviewStatus"
        />
      </div>
      <div class="flex gap-4">
        <h3 class="text-lg text-primary font-bold flex items-center gap-2">
          Oxford level of evidence
        </h3>
        <Switch
          :disabled="review.isPlanReadonly.value"
          :model-value="
            review.entity.value?.plan?.appraisalPlan
              .isOxfordLevelOfEvidenceApplicable
          "
          @update:model-value="toggleAppraisalIsOxfordLevelOfEvidence"
        />
      </div>

      <AppraisalCriteria />
    </div>
  </div>
</template>

<script setup lang="ts">
import Switch from '@app/components/Global/Switch.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import AppraisalCriteria from './AppraisalCriteria.vue'
import InfoTooltip from '@app/components/Global/InfoTooltip.vue'
import { HttpException } from '@core/exceptions/http.exception'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import useLoading from '@app/composables/use-loading'

const review = injectStrict(ReviewKey)
const snackbar = useSnackbar()
const loading = useLoading()

async function toggleAppraisalIsOxfordLevelOfEvidence(value: boolean) {
  loading.start()
  try {
    if (value) {
      await review.enableStudiesAppraisalOxfordLevelOfEvidence()
      snackbar.show(
        SnackbarState.SUCCESS,
        'Appraisal oxford level of evidence enabled successfully',
      )
    } else {
      await review.disableStudiesAppraisalOxfordLevelOfEvidence()
      snackbar.show(
        SnackbarState.SUCCESS,
        'Appraisal oxford level of evidence disabled successfully',
      )
    }
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while enabling/disabling appraisal oxford level of evidence, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}

async function toggleStudiesAppraisalPeerReviewStatus(value: boolean) {
  loading.start()
  try {
    if (value) {
      await review.enableStudiesAppraisalPeerReviewStatus()
      snackbar.show(
        SnackbarState.SUCCESS,
        'Appraisal peer review status enabled successfully',
      )
    } else {
      await review.disableStudiesAppraisalPeerReviewStatus()
      snackbar.show(
        SnackbarState.SUCCESS,
        'Appraisal peer review status disabled successfully',
      )
    }
  } catch (e) {
    const error = e as HttpException
    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode < 500
    ) {
      snackbar.show(SnackbarState.ERROR, error.response.data.message)
    } else {
      snackbar.show(
        SnackbarState.ERROR,
        'An error occured while enabling/disabling appraisal peer review status, please try again.',
      )
    }
    throw e
  } finally {
    loading.stop()
  }
}
</script>
