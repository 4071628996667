<template>
  <div class="flex flex-col overflow-auto">
    <h3
      class="text-2xl text-primary font-medium flex items-center gap-2 px-4 pt-4"
    >
      Scope
    </h3>
    <div v-if="shouldShowPresetSelection" class="p-4 flex-shrink-0 container">
      <GenericAutoComplete
        v-model="preset"
        :readonly="review.isPlanReadonly.value"
        placeholder="select a preset"
        :items="reviewPresets"
        :item-options="{
          displayProperty: 'text',
          filterProperties: ['text', 'value'],
          valueProperty: 'value',
        }"
      >
        <template #label="{ id }">
          <div class="flex gap-1 items-center mb-1">
            <label
              :for="id"
              class="text-sm text-primary font-medium flex items-center gap-2"
            >
              Preset
            </label>
            <InfoTooltip
              text="Each preset automatically generates a specific set of questions tailored for different types of reviews, simplifying the data extraction process."
              url="/docs/reviews.html#configuring-presets"
            />
          </div>
        </template>
      </GenericAutoComplete>
    </div>
    <keep-alive
      v-if="
        (synthesisPlan?.attributesStructure.length ?? 0) <= 0 &&
        !review.isPlanReadonly.value
      "
    >
      <div class="container">
        <component :is="currentComponent"></component>
      </div>
    </keep-alive>

    <AttributesStructureBuilder v-else v-model="synthesisPlan" />
  </div>
</template>

<script setup lang="ts">
import DeviceSpecificSearch from './Presets/DeviceSpecificSearchPreset.vue'
import { computed, ref } from 'vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import MedicalBackgroundPreset from './Presets/MedicalBackgroundPreset.vue'
import GenericDeviceGroupPreset from './Presets/GenericDeviceGroupPreset.vue'
import { ReviewPreset } from '@core/domain/types/review-preset.type'
import CustomPreset from './Presets/CustomPreset.vue'
import InfoTooltip from '@app/components/Global/InfoTooltip.vue'
import AttributesStructureBuilder from './AttributeStructureBuilder/AttributesStructureBuilder.vue'
import SimilarDeviceSpecificSearchPreset from './Presets/SimilarDeviceSpecificSearchPreset.vue'
import MedicalAlternativePreset from './Presets/MedicalAlternativePreset.vue'

const review = injectStrict(ReviewKey)
const synthesisPlan = review.synthesisPlan

const components = {
  [ReviewPreset.MEDICAL_BACKGROUND]: MedicalBackgroundPreset,
  [ReviewPreset.GENERIC_DEVICE_GROUP]: GenericDeviceGroupPreset,
  [ReviewPreset.MEDICAL_ALTERNATIVE]: MedicalAlternativePreset,
  [ReviewPreset.DEVICE_SPECIFIC_SEARCH]: DeviceSpecificSearch,
  [ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH]:
    SimilarDeviceSpecificSearchPreset,
  [ReviewPreset.CUSTOM]: CustomPreset,
}

const preset = ref(ReviewPreset.DEFAULT)

const shouldShowPresetSelection = computed(() => {
  let shouldShow = true
  const plan = review.entity.value.plan
  if ((plan?.synthesisPlan?.attributesStructure?.length ?? 0) > 0) {
    shouldShow = false
  }
  return shouldShow
})

const currentComponent = computed(() => {
  return preset.value ? components[preset.value!] : 'div'
})

const reviewPresets = [
  {
    text: 'Systematic review: Medical background for a specific indication',
    value: ReviewPreset.MEDICAL_BACKGROUND,
  },
  {
    text: 'Systematic review: performance and safety of an intervention (procedure under evaluation)',
    value: ReviewPreset.GENERIC_DEVICE_GROUP,
  },
  {
    text: 'Systematic review: performance and safety of an intervention (alternative technique)',
    value: ReviewPreset.MEDICAL_ALTERNATIVE,
  },
  {
    text: 'PMS literature search: device under evalution',
    value: ReviewPreset.DEVICE_SPECIFIC_SEARCH,
  },

  {
    text: 'PMS lieterature search: similar/equivalent device',
    value: ReviewPreset.SIMILAR_DEVICE_SPECIFIC_SEARCH,
  },

  {
    text: 'Custom preset',
    value: ReviewPreset.CUSTOM,
  },
]
</script>
