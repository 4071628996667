<template>
  <div class="flex-grow h-full">
    <div class="px-4 text-lg">
      The goal is to identify the following information concerning
      <input
        v-model="alternative"
        type="text"
        class="flex-1 pl-1 focus:outline-none border-b-2 min-w-[350px] py-2 transition-colors"
        :class="[isFocused ? 'border-black' : 'border-black/30']"
        placeholder="Type the alternative here and press enter"
        @keydown.enter="applyPreset"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import useLoading from '@app/composables/use-loading'
import useSnackbar from '@app/composables/use-snackbar'
import { SnackbarState } from '@app/types'
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '@app/views/Review/use-review'
import { ReviewPreset } from '@core/domain/types/review-preset.type'
import { ref } from 'vue'
const loading = useLoading()
const snackbar = useSnackbar()

const review = injectStrict(ReviewKey)

const isFocused = ref()

const alternative = ref()

async function applyPreset() {
  if (!alternative.value) {
    snackbar.show(SnackbarState.ERROR, 'Alternative is required')
    return
  }

  try {
    loading.start()
    await review.addMultipleAttributesStructure(review.entity.value.id, {
      preset: ReviewPreset.GENERIC_DEVICE_GROUP,
      alternativeName: alternative.value,
    })
    snackbar.show(
      SnackbarState.SUCCESS,
      'Review presets generated successfully',
    )
  } catch (error) {
    snackbar.show(SnackbarState.ERROR, 'Error while generating review presets')
    console.error('Error adding scope field:', error)
  } finally {
    loading.stop()
  }
}
</script>
