<template>
  <GenericAutoComplete
    :model-value="selectedUser"
    :items="items"
    :item-options="{
      valueProperty: 'id',
      displayProperty: 'fullName',
      filterProperties: ['fullName'],
    }"
    :placeholder="`Type ${role} name here`"
    :display-property-handler="buildUserFullname"
    :can-add-items="true"
    @update:model-value="(v) => updateVModel(v as string)"
    @add-new-item="addNewItem"
  />
</template>
<script setup lang="ts">
import { injectStrict } from '@app/utils/injectStrict'
import { ReviewKey } from '../../use-review'
import { User } from '@core/domain/models/user.model'
import FloatingMenu from '@app/components/Global/FloatingMenu.vue'
import GenericAutoComplete from '@app/components/Global/Inputs/GenericAutoComplete/GenericAutoComplete.vue'
import { ref } from 'vue'

defineProps<{
  role: string
}>()

const review = injectStrict(ReviewKey)
const project = review.entity.value.project!
const items = project.users.map((u) => ({
  id: u.id,
  fullName: `${u.firstName} ${u.lastName}`,
}))
const selectedUser = defineModel<string>()
const floatingMenuElement = ref<InstanceType<typeof FloatingMenu>>()

function buildUserFullname(user: User): string {
  return user ? `${user.firstName} ${user.lastName}` : ''
}

function updateVModel(user: string) {
  const existingUser = items.find((u) => u.id === user)
  if (existingUser) {
    selectedUser.value = existingUser.fullName
  } else {
    selectedUser.value = user
  }
}

function addNewItem(text: string) {
  console.log(text)
}

function close() {
  floatingMenuElement.value?.hide()
}

defineExpose({
  close,
})
</script>
