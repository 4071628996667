<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path fill="#fff" d="M1 10H31V22H1z"></path>
    <path
      d="M5,4H27c2.208,0,4,1.792,4,4v3H1v-3c0-2.208,1.792-4,4-4Z"
      fill="#da352e"
    ></path>
    <path
      d="M5,21H27c2.208,0,4,1.792,4,4v3H1v-3c0-2.208,1.792-4,4-4Z"
      transform="rotate(180 16 24.5)"
      fill="#da352e"
    ></path>
    <path
      d="M27,4H5c-2.209,0-4,1.791-4,4V24c0,2.209,1.791,4,4,4H27c2.209,0,4-1.791,4-4V8c0-2.209-1.791-4-4-4Zm3,20c0,1.654-1.346,3-3,3H5c-1.654,0-3-1.346-3-3V8c0-1.654,1.346-3,3-3H27c1.654,0,3,1.346,3,3V24Z"
      opacity=".15"
    ></path>
    <path
      d="M15.761,11.494c-.133,.52-.944,.762-1.008,1.188,.682-.012-.464,.472-.603,.643,.817,.463-.136,.25-.287,.734-.124,.293-1.546,.664-.858,.91,.307-.14,.796-.194,.656,.302-.144,.543-1.985,.935-1.342,1.446,.235-.015,.397-.207,.597-.305,.442-.101,.388,.538,.01,.582-1.834,1.102-1.688,1.67,.307,.584,1.317,.485-1.679,.731-1.244,1.196,.916,.357,1.968-1.099,2.688-.347,.681,.933-2.177,2.12-1.395,1.991,.31-.343,.893-.111,1.222-.458,.309-.362,1.057-.455,1.369-.155,.096,.161,.516,.602,.647,.445,.085-.843,1.077,.378,1.34,.249,.063-.315-.464-.406-.614-.65-.477-.302-.792-1.944,.047-1.788,.571,.41,1.262,.123,1.892,.288,.295,.067,1.394,1.043,1.136,.258-.291-.527-1.765-.674-1.5-1.3,.24-.287,1.538,.632,.997-.102-.489-.281-.802-.956-1.451-.861-.121-.425,.685-.178,.937-.269,.492,.051,.141-.351-.167-.325-1.04-.175-1.01-.592,.044-.323,.325-.356-2.649-1.511-1.683-1.726,1.015,.383,.917,.136,.114-.354-.145-.124,.123-.178,.215-.131,.241-.74-2.029-.63-2.067-1.719Z"
      fill="#4aa25a"
    ></path>
    <path
      d="M27,5H5c-1.657,0-3,1.343-3,3v1c0-1.657,1.343-3,3-3H27c1.657,0,3,1.343,3,3v-1c0-1.657-1.343-3-3-3Z"
      fill="#fff"
      opacity=".2"
    ></path>
  </svg>
</template>
