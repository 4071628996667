<template>
  <InputScaffold
    :id="id"
    :error="computedError"
    :is-required="isRequired"
    :label="label"
    :is-disabled="disabled"
    @click="focus"
  >
    <div class="flex items-center justify-between w-full">
      <input
        v-if="!multiline"
        :id="id"
        ref="input"
        v-model="value"
        :type="inputType"
        class="appearance-none focus:outline-none w-full disabled:bg-transparent"
        :placeholder="placeholder ?? label"
        :disabled="disabled"
        :class="[inputClasses]"
        :tabindex="tabindex"
        @input="validate"
        @keydown.enter="emit('enter')"
      />
      <textarea
        v-else
        :id="id"
        ref="input"
        v-model="value"
        class="appearance-none focus:outline-none w-full h-[75px] disabled:bg-transparent"
        :placeholder="placeholder ?? label"
        :disabled="disabled"
        :tabindex="tabindex"
        :class="[
          inputClasses,

          {
            'resize-none': !canResize,
          },
        ]"
        @keydown.enter="emit('enter')"
        @input="validate"
      />
      <div tabindex="-1" class="h-full flex top-1/2 text-black">
        <button v-if="clearable && value" tabindex="-1" @click="clear()">
          <XCircleIcon class="w-5 h-5" />
        </button>
        <button
          v-if="type === 'password'"
          tabindex="-1"
          @click="togglePassword()"
        >
          <EyeIcon class="w-5 h-5 dark:fill-white/70 text-primary" />
        </button>
        <label v-else-if="type === 'email'" tabindex="-1" :for="id">
          <EnvelopeIcon
            tabindex="-1"
            class="w-5 h-5 dark:fill-white/70 text-primary"
          />
        </label>
        <label tabindex="-1" :for="id" class="flex items-center">
          <slot name="suffix" />
        </label>
      </div>
    </div>
  </InputScaffold>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import EyeIcon from '@app/components/Icons/EyeIcon.vue'
import XCircleIcon from '../../Icons/XCircleIcon.vue'
import EnvelopeIcon from '@app/components/Icons/EnvelopeIcon.vue'
import InputScaffold from './InputScaffold.vue'

const input = ref<HTMLInputElement | HTMLTextAreaElement>()
const id = uuidv4()

const props = withDefaults(
  defineProps<{
    type?: 'text' | 'password' | 'email'
    modelValue?: string
    label?: string
    clearable?: boolean
    placeholder?: string
    rules?: ((v: string) => boolean | string)[]
    disabled?: boolean
    multiline?: boolean
    error?: string
    tabindex?: number
    inputClasses?: string
    isRequired?: boolean
    canResize?: boolean
  }>(),
  {
    type: 'text',
    clearable: false,
    modelValue: '',
    rules: () => [],
    label: '',
    placeholder: '',
    disabled: false,
    multiline: false,
    error: '',
    tabindex: 0,
    inputClasses: '',
    isRequired: false,
    canResize: false,
  },
)
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'enter'): void
}>()

const value = computed<string>({
  get() {
    return props.modelValue
  },

  set(value) {
    emit('update:modelValue', value)
  },
})

const isPasswordShown = ref(false)
const inputType = computed(() =>
  props.type === 'text' || props.type === 'email' || isPasswordShown.value
    ? 'text'
    : 'password',
)

function togglePassword() {
  isPasswordShown.value = !isPasswordShown.value
}

function clear() {
  emit('update:modelValue', '')
}

const internalError = ref<string>()

const computedError = computed(() =>
  props.error ? props.error : internalError.value,
)

function validate(): boolean {
  const errors = props.rules
    .map((r) => r(value.value))
    .filter((r) => typeof r === 'string')
  internalError.value = errors.join(' ')
  return errors.length <= 0
}

function focus() {
  input.value?.focus({
    preventScroll: true,
  })
}
function select() {
  input.value?.select()
}

defineExpose({
  validate,
  focus,
  select,
})
</script>
