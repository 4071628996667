²
<template>
  <div class="w-full">
    <label
      v-if="label"
      :for="id"
      class="text-sm text-primary font-medium pointer-events-none"
    >
      {{ label }}
      <span v-if="isRequired" class="text-red-600">*</span>
    </label>
    <div
      class="flex w-full items-center justify-between gap-1 border border-slate-300 rounded-md drop-shadow"
      :class="[isDisabled ? 'bg-black/5' : 'bg-white']"
    >
      <div
        class="cursor-text h-full flex gap-2 justify-between items-center w-full px-2 py-1"
        @click.stop="emit('click')"
      >
        <slot />
      </div>
      <InformationCircleIcon
        v-if="error"
        v-tooltip.error="error ? error : ''"
        class="w-4 h-4 mr-2 text-red-600 outline-none"
        :class="{
          'text-transparent': !error,
        }"
      />

      <div v-else tabindex="-1" class="w-4 h-4 text-transparent outline-none" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { InformationCircleIcon } from '@heroicons/vue/24/outline'

withDefaults(
  defineProps<{
    id: string
    label?: string
    error?: string
    isRequired?: boolean
    isDisabled?: boolean
  }>(),
  {
    label: '',
    error: '',
    isRequired: false,
    isDisabled: false,
  },
)

const emit = defineEmits(['click'])
</script>
