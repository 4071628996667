import { ProjectsService } from '@core/application/projects.service'
import { ReviewsService } from '@core/application/reviews.service'
import { Project } from '@core/domain/models/project.model'
import { Id } from '@core/domain/types/id.type'
import { Ref, ref } from 'vue'
import { useSettings } from '../../composables/use-settings'
import useDisplaySettings from './use-display-settings'
import { ProjectsServiceKey, ReviewsServiceKey } from '@app/injectionKeys'
import { injectStrict } from '@app/utils/injectStrict'

const settings = useSettings()
const displaySettings = useDisplaySettings()

const projects: Ref<Project[]> = ref([])
export default () => {
  const projectsService: ProjectsService = injectStrict(ProjectsServiceKey)
  const reviewsService: ReviewsService = injectStrict(ReviewsServiceKey)

  const create = async (data: { name: string }) => {
    const project = await projectsService.create(data)
    projects.value.unshift(project)
    return project
  }

  const createReview = async (projectId: Id, name: string) => {
    await projectsService.createReview(projectId, name)
    await refresh()
  }

  const refresh = async () => {
    const fetchedProjects = await projectsService.find()
    projects.value = fetchedProjects.map((p) => {
      settings.favoriteProjects.value?.forEach((fav) => {
        if (p.id === fav) p.isFavorite = true
      })
      return p
    })
  }

  function toggleFavorite(projectId: Id) {
    const projectIndex = projects.value.findIndex((p) => p.id === projectId)
    if (!projects.value[projectIndex].isFavorite) {
      projects.value[projectIndex].isFavorite = true
      settings.addProjectTofavorites(projectId)
    } else {
      projects.value[projectIndex].isFavorite = false
      settings.removeProjectFromfavorites(projectId)
    }
  }

  async function deleteSelectedReviews(
    projectId: Id,
    selectedSearchesIds: Id[],
  ) {
    const project = projects.value.find((p) => p.id === projectId)
    if (!project) throw new Error('project not found')
    await reviewsService.delete(selectedSearchesIds)
    project.reviews =
      project?.reviews?.filter((s) => !selectedSearchesIds.includes(s.id)) ?? []
  }

  async function deleteProject(projectId: Id) {
    const project = projects.value.find((p) => p.id === projectId)
    if (!project) throw new Error('project not found')
    await projectsService.delete(projectId)
    settings.removeProjectFromfavorites(projectId)
    projects.value = projects.value.filter((p) => p.id !== projectId)
  }

  async function updateName(projectId: Id, name: string) {
    await projectsService.updateProjectName(projectId, name)
    const index = projects.value.findIndex((p) => p.id === projectId)
    projects.value[index] = { ...projects.value[index], name }
  }

  async function updateContactForComplaintEmail(
    projectId: Id,
    contactForComplaintEmail: string,
  ) {
    await projectsService.updateContactForComplaintEmail(
      projectId,
      contactForComplaintEmail,
    )
    const index = projects.value.findIndex((p) => p.id === projectId)
    projects.value[index] = {
      ...projects.value[index],
      contactForComplaintEmail,
    }
  }

  async function updateReviewName(projectId: Id, reviewId: Id, name: string) {
    await reviewsService.updateName(reviewId, name)
    const projectIndex = projects.value.findIndex((p) => p.id === projectId)
    if (projectIndex === -1) throw new Error('project not found')
    const searchIndex =
      projects.value[projectIndex].reviews?.findIndex(
        (s) => s.id === reviewId,
      ) ?? -1
    if (searchIndex === -1) throw new Error('search not found')

    const project = { ...projects.value[projectIndex] }
    projects.value[projectIndex].reviews![searchIndex].name = name
    projects.value[projectIndex] = { ...project }
  }

  async function updateCslStyle(projectId: Id, cslStyle: string) {
    await projectsService.updateCslStyle(projectId, cslStyle)
    const index = projects.value.findIndex((p) => p.id === projectId)
    projects.value[index].cslStyle = cslStyle
  }

  async function addProjectMember(projectId: Id, userId: string) {
    await projectsService.addMember(projectId, userId)
    await refresh()
  }

  async function removeProjectMember(projectId: Id, userId: string) {
    await projectsService.removeMember(projectId, userId)
    await refresh()
  }

  async function setReviewsPlanLocking(
    projectId: Id,
    useReviewPlanLocking: boolean,
  ) {
    await projectsService.setReviewsPlanLocking(projectId, useReviewPlanLocking)
    await refresh()
  }

  async function archiveReview(reviewId: Id) {
    await reviewsService.archive(reviewId)
    await refresh()
  }

  async function unarchiveReview(reviewId: Id) {
    await reviewsService.unarchive(reviewId)
    await refresh()
  }

  return {
    projects,
    displaySettings,
    create,
    createReview,
    refresh,
    deleteSelectedReviews,
    deleteProject,
    updateName,
    updateReviewName,
    updateCslStyle,
    updateSupevisorEmail: updateContactForComplaintEmail,
    toggleFavorite,
    addProjectMember,
    removeProjectMember,
    setReviewsPlanLocking,
    archiveReview,
    unarchiveReview,
  }
}
