import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouteComponent,
} from 'vue-router'
import Login from './views/Login.vue'
import { Role } from '@core/domain/types/role.type'
import ProjectsVue from './views/Projects/Projects.vue'
import { HomeIcon } from '@heroicons/vue/24/outline'
import UsersVue from './views/Users.vue'
import UsersIconVue from './components/Icons/UsersIcon.vue'
import AsyncReview from './views/AsyncReview.vue'
import Settings from './views/Settings/Settings.vue'
import SettingsIcon from './components/Icons/SettingsIcon.vue'
import { User } from '@core/domain/models/user.model'
import ForgotPassword from './views/ForgotPassword.vue'

export enum RouteName {
  HOME = 'home',
  PROJECTS = 'projects',
  USERS = 'users',
  SETTINGS = 'settings',
}

export interface NagivationEntry {
  to: string
  roles: Role[]
  name: RouteName
  component: RouteComponent
  icon: RouteComponent
  text: string
  isCuttingEdge?: boolean
}
export const navigation: NagivationEntry[] = [
  {
    to: '/',
    component: ProjectsVue,
    roles: [],
    name: RouteName.HOME,
    icon: HomeIcon,
    text: 'Home',
  },
  {
    to: '/projects',
    component: ProjectsVue,
    roles: [],
    name: RouteName.PROJECTS,
    icon: HomeIcon,
    text: 'Projects',
  },
  {
    to: '/users',
    component: UsersVue,
    roles: [Role.ADMIN],
    name: RouteName.USERS,
    icon: UsersIconVue,
    text: 'Users',
  },
  {
    to: '/settings',
    component: Settings,
    roles: [],
    name: RouteName.SETTINGS,
    icon: SettingsIcon,
    text: 'Settings',
  },
]

export function userCanAccess(routeName: string, user: User): boolean {
  const route = navigation.find((r) => r.name === routeName)
  if (!route) throw new Error('incorrect route name')
  if (route.roles.length <= 0) return true
  if (!user) return false
  return route.roles.includes(user.role)
}

const routes: RouteRecordRaw[] = [
  {
    path: '/reviews/:id',
    component: AsyncReview,
    name: 'review',
  },
  { path: '/login', component: Login, name: 'login' },
  {
    path: '/forgot-password',
    component: ForgotPassword,
    name: 'forgotPassword',
  },

  {
    path: '/terms-of-service',
    component: () => import('./views/TermsOfService.vue'),
    name: 'termsOfService',
  },
  ...navigation.map((n) => ({
    path: n.to,
    component: n.component,
    name: n.name,
  })),
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})
