<template>
  <div class="border border-primary rounded-lg p-4">
    <p class="font-bold mb-4">Field safety notices search</p>
    <TextInput
      v-model="searchQuery"
      placeholder="Search"
      class="w-full mb-4"
      @enter="onEnter"
    />
    <div class="h-[600px] overflow-auto relative">
      <div
        v-if="isLoading"
        class="overflow-hidden absolute top-0 left-0 w-full h-1"
      >
        <div class="line absolute bg-primary/20 h-1"></div>
        <div class="absolute bg-primary h-1 inc"></div>
        <div class="absolute bg-primary h-1 dec"></div>
      </div>
      <ul v-if="results.length" class="mt-4 space-y-2 gap-2 pr-2">
        <li
          v-for="article in results"
          :key="article._formatted.id"
          class="p-2 border rounded"
        >
          <template v-if="isLoading"> Loading </template>
          <template v-else>
            <a target="_blank" :href="article.link" class="text-primary">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <p v-html="article._formatted.title" />
              <div class="flex items-center gap-4 text-sm pb-2 pt-1">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-html="article._formatted.source" />
              </div>
            </a>
          </template>
        </li>
      </ul>
      <div v-else-if="totalResults === 0" class="mt-4">No results</div>
      <div v-else class="mt-4"></div>
    </div>
    <div v-if="totalPages > 1" class="mt-6 flex gap-2 items-center">
      <button
        :disabled="currentPage === 1 || isLoading"
        class="px-4 py-2 bg-gray-300 rounded disabled:bg-gray-100"
        @click="prevPage"
      >
        Previous
      </button>
      <span>Page {{ currentPage }} of {{ totalPages }} </span>
      <button
        :disabled="currentPage === totalPages || isLoading"
        class="px-4 py-2 bg-gray-300 rounded disabled:bg-gray-100"
        @click="nextPage"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import TextInput from '@app/components/Global/Inputs/TextInput.vue'
import { ref } from 'vue'
import { MeiliSearch } from 'meilisearch'

const client = new MeiliSearch({
  host: location.protocol + '//' + location.host + '/fsn-api',
})

type Article = {
  id: string
  title: string
  date: string
  link: string
  summary: string
  source: string
  fulltext?: string
  type: string
  _formatted: Omit<Article, '_formatted'>
}

const searchQuery = ref('')
const results = ref<Article[]>([])
const currentPage = ref(1)
const totalPages = ref(1)
const limit = ref(12)
const totalResults = ref(-1)

const isLoading = ref(false)

async function search() {
  if (!searchQuery.value) {
    return
  }
  isLoading.value = true
  try {
    const offset = (currentPage.value - 1) * limit.value
    const highlightPreTag = '<mark class="bg-yellow-400">'
    const highlightPostTag = '</mark>'

    const searchResults = await client.multiSearch({
      federation: {
        limit: limit.value,
        offset: offset,
      },
      queries: [
        {
          indexUid: 'bfarm',
          q: searchQuery.value,
          highlightPreTag,
          attributesToHighlight: ['*'],
          highlightPostTag,
          matchingStrategy: 'all',
        },
        {
          indexUid: 'ansm',
          q: searchQuery.value,
          highlightPreTag,
          attributesToHighlight: ['*'],
          highlightPostTag,
          matchingStrategy: 'all',
        },
        {
          indexUid: 'sara',
          q: searchQuery.value,
          highlightPreTag,
          attributesToHighlight: ['*'],
          highlightPostTag,
          matchingStrategy: 'all',
        },
        {
          indexUid: 'mhra',
          q: searchQuery.value,
          highlightPreTag,
          attributesToHighlight: ['*'],
          highlightPostTag,
          matchingStrategy: 'all',
        },
      ],
    })

    results.value = searchResults.hits as unknown as Article[]
    totalResults.value = searchResults.estimatedTotalHits ?? 0
    totalPages.value = Math.ceil(totalResults.value / limit.value)
  } catch (error) {
    console.error('Error searching documents:', error)
  } finally {
    isLoading.value = false
  }
}

function onEnter() {
  reset()
  search()
}

function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value += 1
    search()
  }
}

function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value -= 1
    search()
  }
}

function reset() {
  results.value = []
  currentPage.value = 1
  totalPages.value = 1
  totalResults.value = -1
}
</script>
