import { StudyState } from '@app/types'
import { Id } from '../types/id.type'
import { ReviewItem } from './reviewItem.model'
import { Search } from './search.model'
import { LiteratureReviewPlan } from './literature-review-plan.model'
import { Project } from './project.model'
import { ImportSourceType } from '@core/domain/types/import-source-type.type'
import { User } from './user.model'
import { INCLUDED } from '../types/included-screening-criterion.type'
import { FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE } from '../types/full-text-cannot-be-retrieved-screening-criterion.type'
import { ReviewItemType } from '../types/reviewItemType.type'
import { Article } from './article.model'
import { Incident } from './incident.model'
import { Guidance } from './guidance.model'
import { Registration } from './registration.model'
import { EvaluatorGroup } from './evaluatorGroup.model'

export type StatsEntry = {
  total: number
  included: number
  duplicate: number
  titleAndAbstractExcludedScreeningTotal: number
  fullTextExcludedScreeningTotal: number
  titleAndAbstractExcludedScreening: { [criterion: string]: number }
  fullTextExcludedScreening: { [criterion: string]: number }
  soughtForRetrieval: number
  assessedForEligibility: number
  reportsNotRetrieved: number
  unscreened: number
  quarantined: number
}

export type Stats = {
  overall: StatsEntry
  sources: { [key: string]: StatsEntry }
  searches: { [key: string]: StatsEntry }
  sourceTypes: {
    [key in ImportSourceType]?: StatsEntry
  }
}

export class Review {
  id: Id
  name: string
  plan?: LiteratureReviewPlan
  searches?: Search[]
  project?: Project
  authors: User[]
  reviewers: User[]
  approvers: User[]
  externalApprovers: { name: string; id: string }[]
  isLocked: boolean
  isArchived: boolean
  evaluatorGroups: EvaluatorGroup[]

  private studySearchMap: Map<Id, number> = new Map()

  constructor(data: Partial<Review>) {
    this.id = data.id ?? 0
    this.name = data.name ?? ''
    this.plan = data.plan
    this.searches = data.searches ?? []
    this.project = data.project
    this.authors = data.authors ?? []
    this.reviewers = data.reviewers ?? []
    this.approvers = data.approvers ?? []
    this.externalApprovers = data.externalApprovers ?? []
    this.initializeStudySearchMap()
    this.isLocked = data.isLocked ?? false
    this.isArchived = data.isArchived ?? false
    this.evaluatorGroups = data.evaluatorGroups ?? []
  }

  static create(data: Partial<Review>) {
    const review = new Review({
      ...data,
      searches:
        data.searches?.map(
          (f) =>
            new Search({
              ...f,
              studies: f.studies.map((i) => {
                if (i.type === ReviewItemType.Incident) {
                  return new Incident(i)
                } else if (i.type === ReviewItemType.Guidance) {
                  return new Guidance(i)
                } else if (i.type === ReviewItemType.Registration) {
                  return new Registration(i)
                } else {
                  return new Article(i)
                }
              }),
            }),
        ) ?? [],
    })
    return review
  }

  get studies(): ReviewItem[] {
    return (
      this.searches?.reduce(
        (acc, s) => acc.concat(s.studies ?? []),
        [] as ReviewItem[],
      ) ?? []
    )
  }

  updateStudy(studyId: Id, partialStudy: Partial<ReviewItem>) {
    const searchIndex = this.studySearchMap.get(studyId)
    if (searchIndex === undefined) throw new Error('Study not found')

    this.searches![searchIndex] = new Search({
      ...this.searches![searchIndex],
      studies: this.searches![searchIndex].studies.map((s) => {
        if (s.id === studyId) {
          const updatedData = { ...s, ...partialStudy }
          if (s.type === ReviewItemType.Incident) {
            return new Incident(updatedData)
          } else if (s.type === ReviewItemType.Guidance) {
            return new Guidance(updatedData)
          } else if (s.type === ReviewItemType.Registration) {
            return new Registration(updatedData)
          } else return new Article(updatedData)
        }
        return s
      }),
    })
  }

  getSearchIndex(study: ReviewItem): number {
    return this.studySearchMap.get(study.id) ?? -1
  }

  removeImportSource(importSourceId: string) {
    this.plan?.importPlan?.importSources?.forEach((source, index) => {
      if (source.id === importSourceId) {
        this.plan?.importPlan.importSources?.splice(index, 1)
      }
    })
  }

  get stats(): Stats {
    //#region setup
    const initStatsEntry = () => ({
      total: 0,
      included: 0,
      duplicate: 0,
      titleAndAbstractExcludedScreeningTotal: 0,
      fullTextExcludedScreeningTotal: 0,
      titleAndAbstractExcludedScreening: {},
      fullTextExcludedScreening: {},
      soughtForRetrieval: 0,
      assessedForEligibility: 0,
      reportsNotRetrieved: 0,
      unscreened: 0,
      quarantined: 0,
    })
    const stats: Stats = {
      overall: initStatsEntry(),
      sources: {},
      searches: {},
      sourceTypes: {},
    }

    const initializeCriteria = (entry: StatsEntry) => {
      this.plan?.screeningPlan?.titleAndAbstractCriteria?.forEach((s1c) => {
        entry.titleAndAbstractExcludedScreening[s1c] = 0
      })
      this.plan?.screeningPlan?.fullTextCriteria
        .concat(FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE)
        .forEach((s2c) => (entry.fullTextExcludedScreening[s2c] = 0))
    }

    this.plan?.screeningPlan?.titleAndAbstractCriteria?.forEach((s1c) => {
      stats.overall.titleAndAbstractExcludedScreening[s1c] = 0
    })
    this.plan?.screeningPlan?.fullTextCriteria
      .concat(FULL_TEXT_CANNOT_BE_RETRIEVED_CRITERION_TYPE)
      .forEach((s2c) => (stats.overall.fullTextExcludedScreening[s2c] = 0))

    this.plan?.importPlan?.importSources?.forEach((source) => {
      stats.sources[source.id] = initStatsEntry()
      stats.sourceTypes[source.type] = initStatsEntry()
      initializeCriteria(stats.sources[source.id])
      initializeCriteria(stats.sourceTypes[source.type]!)
    })

    this.searches?.forEach((_, index) => {
      stats.searches[`search ${index + 1}`] = initStatsEntry()
      initializeCriteria(stats.searches[`search ${index + 1}`])
    })

    //#endregion

    this.studies.forEach((s) => {
      const index = this.getSearchIndex(s)
      const sourceType = s.search.source?.type

      stats.overall.total += 1
      stats.searches[`search ${index + 1}`].total += 1
      stats.sources[s.search.source?.id].total += 1
      stats.sourceTypes[sourceType]!.total += 1

      if (s.titleAndAbstractScreening === INCLUDED) {
        stats.overall.soughtForRetrieval += 1
        stats.searches[`search ${index + 1}`].soughtForRetrieval += 1
        stats.sources[s.search.source.id].soughtForRetrieval += 1
        stats.sourceTypes[sourceType]!.soughtForRetrieval += 1
      }

      if (s.titleAndAbstractScreening === INCLUDED && s.pdfFile) {
        stats.overall.assessedForEligibility += 1
        stats.searches[`search ${index + 1}`].assessedForEligibility += 1
        stats.sources[s.search.source.id].assessedForEligibility += 1
        stats.sourceTypes[sourceType]!.assessedForEligibility += 1
      }
      if (s.titleAndAbstractScreening === INCLUDED && !s.pdfFile) {
        stats.overall.reportsNotRetrieved += 1
        stats.searches[`search ${index + 1}`].reportsNotRetrieved += 1
        stats.sources[s.search.source.id].reportsNotRetrieved += 1
        stats.sourceTypes[sourceType]!.reportsNotRetrieved += 1
      }
      if (s.state === StudyState.DUPLICATE) {
        stats.overall.duplicate += 1
        stats.searches[`search ${index + 1}`].duplicate += 1
        stats.sources[s.search.source.id].duplicate += 1
        stats.sourceTypes[sourceType]!.duplicate += 1
      } else if (s.state === StudyState.QUARANTINED) {
        stats.overall.quarantined += 1
        stats.searches[`search ${index + 1}`].quarantined += 1
        stats.sources[s.search.source.id].quarantined += 1
        stats.sourceTypes[sourceType]!.quarantined += 1
      } else if (s.state === StudyState.INCLUDED) {
        stats.overall.included += 1
        stats.searches[`search ${index + 1}`].included += 1
        stats.sources[s.search.source.id].included += 1
        stats.sourceTypes[sourceType]!.included += 1
      } else if (
        s.state === StudyState.EXCLUDED &&
        s.titleAndAbstractScreening !== INCLUDED &&
        (this.plan?.screeningPlan?.titleAndAbstractCriteria?.length ?? 0) > 0
      ) {
        stats.overall.titleAndAbstractExcludedScreeningTotal += 1
        stats.searches[
          `search ${index + 1}`
        ].titleAndAbstractExcludedScreeningTotal += 1
        stats.sources[
          s.search.source.id
        ].titleAndAbstractExcludedScreeningTotal += 1
        stats.sourceTypes[sourceType]!.titleAndAbstractExcludedScreeningTotal +=
          1

        stats.overall.titleAndAbstractExcludedScreening[
          s.titleAndAbstractScreening!
        ] += 1
        stats.searches[`search ${index + 1}`].titleAndAbstractExcludedScreening[
          s.titleAndAbstractScreening!
        ] += 1
        stats.sources[s.search.source.id].titleAndAbstractExcludedScreening[
          s.titleAndAbstractScreening!
        ] += 1
        stats.sourceTypes[sourceType]!.titleAndAbstractExcludedScreening[
          s.titleAndAbstractScreening!
        ] += 1
      } else if (
        s.state === StudyState.EXCLUDED &&
        s.fullTextScreening !== INCLUDED
      ) {
        stats.overall.fullTextExcludedScreeningTotal += 1
        stats.searches[`search ${index + 1}`].fullTextExcludedScreeningTotal +=
          1
        stats.sources[s.search.source.id].fullTextExcludedScreeningTotal += 1
        stats.sourceTypes[sourceType]!.fullTextExcludedScreeningTotal += 1

        stats.overall.fullTextExcludedScreening[s.fullTextScreening] += 1
        stats.searches[`search ${index + 1}`].fullTextExcludedScreening[
          s.fullTextScreening
        ] += 1
        stats.sources[s.search.source.id].fullTextExcludedScreening[
          s.fullTextScreening
        ] += 1
        stats.sourceTypes[sourceType]!.fullTextExcludedScreening[
          s.fullTextScreening
        ] += 1
      } else if (s.state === StudyState.UNSCREENED) {
        stats.overall.unscreened += 1
        stats.searches[`search ${index + 1}`].unscreened += 1
        stats.sources[s.search.source.id].unscreened += 1
        stats.sourceTypes[sourceType]!.unscreened += 1
      }
    })
    return stats
  }

  private initializeStudySearchMap() {
    this.searches?.forEach((search, index) => {
      search.studies?.forEach((study) => {
        this.studySearchMap.set(study.id, index)
      })
    })
  }

  // DO NOT REMOVE THIS PROPERTY
  // @ts-expect-error Property to enforce instance
  private _enforce_instance = ''
}
